<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="content-card-panel" v-loading="loading">
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px">
              <el-form-item label="标题" prop="title">
                <el-input v-model="editForm.title"></el-input>
              </el-form-item>
              <el-form-item label="发布时间" prop="publishTime">
                <el-date-picker
                    v-model="editForm.publishTime"
                    type="datetime"
                    :picker-options="pickerOptions"
                    default-time="07:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="发布时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="文案">
                <el-input v-model="editForm.intro"></el-input>
              </el-form-item>
              <el-form-item label="分类" prop="categories">
                <category-select v-model="editForm.categories"></category-select>
              </el-form-item>
              <el-form-item label="标签">
                <dynamic-tag v-model="editForm.tags"></dynamic-tag>
              </el-form-item>
              <el-form-item label="来源">
                <div class="mb10">
                  <el-select v-model="editForm.source" placeholder="来源" class="select-source"
                             @change="handleSourceChange">
                    <el-option :value="1" label="原创"></el-option>
                    <el-option :value="2" label="转载"></el-option>
                  </el-select>
                </div>
                <div class="mb10">
                  <el-input v-model="editForm.originalLink" placeholder="请输入原文链接" clearable
                            v-if="editForm.source === 2"></el-input>
                </div>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="editForm.isTop">置顶</el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="将素材添加到推送列表，需要配合发布时间使用，系统默认每天08:00给用户推送消息，需要将发布时间设置为08:00以前">
                  <el-checkbox v-model="editForm.isPush" slot="reference">推送&nbsp;<i
                      class="el-icon-warning-outline"></i>
                  </el-checkbox>
                </el-popover>
              </el-form-item>
              <el-form-item label="字体颜色">
                <el-color-picker v-model="editForm.fontColor"></el-color-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :loading="loadingPublish" @click="handlePublish">发布</el-button>
                <!--            <el-button type="default" :loading="loadingSave" @click="save">保存草稿</el-button>-->
                <el-button plain @click="handleGotoEditPage">继续新增</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="10">
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :limit="1"
                :before-upload="handleHoverBeforeUpload">
              <div v-if="editForm.imageUrl"
                   :style="{ 'background-image':'url(' + editForm.imageUrl + ')' }"
                   class="image-preview">
                <div class="image-press">
                  <div class="press-text" :style="{color: editForm.fontColor}">
                    <span>刘先生 187****8704</span><br/>
                    <span>厦门素客通软件有限公司</span>
                  </div>
                  <div class="press-img"><img :src="qrcode"/></div>
                </div>
              </div>
              <!--                    <img v-if="editForm.imageUrl" :src="editForm.imageUrl" class="avatar"/>-->
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CmsPosterApi, CommonQiniuApi } from '@/api'
import { MATERIAL_SOURCE, MATERIAL_STATUS } from '@/utils/contants'

import qrcode from '@/assets/images/qrcode.png'
import moment from 'moment'

const defaultForm = () => {
  return {
    id: null,
    title: '',
    intro: '',
    tags: [],
    categories: [],
    isTop: false,
    originalLink: null,
    source: MATERIAL_SOURCE.reprint,
    status: MATERIAL_STATUS.publish,
    imageUrl: null,
    fontColor: '#000000',
    publishTime: moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
    isPush: false
  }
}
export default {
  name: 'PosterEdit',
  data () {
    return {
      editForm: defaultForm(),
      loading: false,
      loadingPublish: false,
      loadingSave: false,
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        categories: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }
        ],
        publishTime: [
          {
            required: true,
            message: '请选择发布时间',
            trigger: 'blur'
          }
        ]
      },
      qrcode: qrcode,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < moment().add(-1, 'd').toDate()
        }
      }
    }
  },
  methods: {
    initData () {
      const articleId = this.$route.query.id
      if (articleId) {
        this.loading = true
        CmsPosterApi.getPoster(articleId).then((result) => {
          this.loading = false
          Object.assign(this.editForm, result)
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.editForm = defaultForm()
      }
    },
    handleHoverBeforeUpload (file) {
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((result) => {
        this.editForm.imageUrl = result.fileUrl
      })
      return false
    },
    handleSourceChange (val) {
      if (val === MATERIAL_SOURCE.original) {
        this.editForm.originalLink = null
      }
    },
    valid () {
      if (this.editForm.imageUrl === null || this.editForm.imageUrl.length === 0) {
        this.$message.error('请上传图片')
        return false
      }
      return true
    },
    async doSave () {
      const data = await CmsPosterApi.savePoster(this.editForm)
      Object.assign(this.editForm, data)
    },
    handlePublish () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (!this.valid()) {
            return false
          }
          this.editForm.status = MATERIAL_STATUS.publish
          this.loadingPublish = true
          this.doSave().then(() => {
            this.$message.success('发布成功')
            this.loadingPublish = false
          }).catch(() => {
            this.loadingPublish = false
          })
        } else {
          return false
        }
      })
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (!this.valid()) {
            return false
          }
          this.editForm.status = MATERIAL_STATUS.saved
          this.loadingSave = true
          this.doSave().then(() => {
            this.$message.success('保存成功')
            this.loadingSave = false
          }).catch(() => {
            this.loadingSave = false
          })
        } else {
          return false
        }
      })
    },
    handleGotoEditPage () {
      this.$router.replace({
        path: '/refresh',
        query: {
          id: '',
          t: Date.now()
        }
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 600px;
  line-height: 600px;
  text-align: center;
}

.avatar {
  width: 300px;
  height: 600px;
  display: block;
}

.image-preview {
  flex: 0 0 auto;
  position: relative;
  width: 300px;
  height: 600px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;

  .image-press {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding-bottom: 10px;

    .press-text {
      flex: 70%;
      text-align: left;
      padding-left: 10px;
    }

    .press-img {
      flex: 20%;
      text-align: right;
      padding-right: 10px;

      img {
        width: 46px;
        height: 46px;
      }
    }
  }
}
</style>
